import Slide from "./Componentes/Slide";
import SlideShow from "./SlideShow";
import Contenido from "../../../Contenido.json";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPiggyBank } from "@fortawesome/free-solid-svg-icons";
const Landing = () => {
  return (
    <section className="landing">
      <div className="contenedor-textos">
        <FontAwesomeIcon icon={faPiggyBank} className="icono" />
        <h1>El préstamo que tu empresa <span>necesita.</span></h1>
        <h2>Tenemos la mejor tasa de interés del mercado.</h2>
      </div>
      <div className="contenedor-imagen">
      <img src="/Assets/Landing/landing.jpg" alt="fideapech-landing" />
        <img className="responsive" src="/Assets/Landing/landing-responsive.png" alt="fideapech-landing" />
        
      </div>
    </section>
  );
};

export default Landing;
