import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import ResponsiveMenu from "./ResponsiveMenu";
import ModalidadesList from "./ModalidadesList";
import PopUpNotificaciones from "../../PopUp/PopUp";
const Header = () => {
  const location = useLocation();
  const [show, setShow] = useState(true);
  const [showResponsiveMenu, setShowResponsiveMenu] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [backgorundColor, setBackgorundColor] = useState(true);
  const [modalidadesList, setModalidadesList] = useState(false);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShowResponsiveMenu(false);
        if (window.screenY > 200) {
          setShow(false);
        } else {
          setShow(true);
        }
      } else {
        // if scroll up show the navbar
        setShow(true);
        setShowResponsiveMenu(false);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  const cambiarColorNavBar = () => {
    let yPos = window.scrollY;

    if (yPos > 200) {
      setBackgorundColor("header-color");
    } else {
      if (location.pathname === "/") {
        setBackgorundColor("header");
      } else {
        setBackgorundColor("header");
      }
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined" && location.pathname.split("/")[1] !== "CalculadoraEmisiones") {
      window.addEventListener("scroll", controlNavbar);
      cambiarColorNavBar();
      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  useEffect(() => {
    let header = document.querySelector("header");
    let footer = document.querySelector("footer")
    
    if (location.pathname.split("/")[1] === "CalculadoraEmisiones") {
        header.classList.add("header-emisiones");
        footer.classList.add("footer-emisiones")
    }else{
      //console.log("no es calculadora")
      
        header.classList.remove("header-emisiones");
        header.classList.add("header")
        header.classList.remove("footer-emisiones")
    }
  }, [location.pathname]);

  return (
    <header className={backgorundColor}>
      <PopUpNotificaciones />
      <Link style={{ textDecoration: "none" }} to={"/"}>
        <h2>fideapech</h2>
      </Link>
      <div className="icono-menu">

        <button onClick={() => setShowResponsiveMenu(!showResponsiveMenu)}>MENÚ</button>
      </div>

      <div className="menu-container">
        <Link
          style={{ display: "flex", textDecoration: "none" }}
          to="/Quienes-Somos"
        >
          <button className="button">¿Quiénes Somos?</button>
        </Link>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
          className="button"
        >
          <Link to="/Modalidades">
            <button className="button">Modalidades</button>
          </Link>
        </div>

        <Link
          style={{ display: "flex", textDecoration: "none" }}
          to="/Directorio"
        >
          <button className="button">Directorio</button>
        </Link>
        {/*<button className='button'>Artículos</button>*/}
        <Link style={{ display: "flex", textDecoration: "none" }} to="/FAQ">
          <button className="button">Preguntas Frecuentes</button>
        </Link>
        <Link
          style={{ display: "flex", textDecoration: "none" }}
          to="/Contacto"
        >
          
          <button className="button">Contacto</button>
        </Link>
        <Link
          style={{ display: "flex", textDecoration: "none" }}
          to="/CalculadoraEmisiones"
        >
          
          <button className="button">Calculadora de Emisiones</button>
        </Link>
        <div className="redes-sociales-container">
          <FontAwesomeIcon
            onClick={() =>
              window.open("https://www.facebook.com/fideapech/", "_blank")
            }
            className="icono"
            icon={faFacebookF}
          />
          <FontAwesomeIcon
            onClick={() =>
              window.open("https://www.instagram.com/fideapech/", "_blank")
            }
            className="icono"
            icon={faInstagram}
          />
          <FontAwesomeIcon
            onClick={() =>
              window.open("https://www.youtube.com/@fideapech", "_blank")
            }
            className="icono"
            icon={faYoutube}
          />
          <FontAwesomeIcon
            onClick={() =>
              window.open("https://www.linkedin.com/company/fideapech-2021-2027/", "_blank")
            }
            className="icono"
            icon={faLinkedinIn}
          />
        </div>
      </div>
      <div className="logos-container">
        <img className="secretaria" src="/Assets/Logo/gobierno.png" alt="logoGob" />
  
      </div>
      {showResponsiveMenu ? (
        <ResponsiveMenu handler={setShowResponsiveMenu} />
      ) : null}
    </header>
  );
};

export default Header;
