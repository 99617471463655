import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
const ResponsiveMenu = ({handler}) => {
  const redirect = () => {
    window.open('www.facebook.com', '_blank');
  };
  return (
    <div onClick={() => handler(false)} className='responsive-menu'>
      <div className='menu-container-responsive'>
      <Link
          className='row'
          to='/Quienes-Somos'
        >
        <button onClick={() => handler(false)} className='button'>¿Quiénes Somos?</button>
        </Link>
        <Link
          className='row'
          to='/Modalidades'
        >
        <button onClick={() => handler(false)} className='button'>Modalidades</button>
        </Link>
        <Link
          className='row'
          to='/Directorio'
        >
          <button onClick={() => handler(false)} className='button'>Directorio</button>
        </Link>

        <Link
          className='row'
          to='/FAQ'
        >
          <button onClick={() => handler(false)} className='button'>Preguntas Frecuentes</button>
        </Link>
        <Link
          className='row'
          to='/Contacto'
        >
          <button onClick={() => handler(false)} className='button'>Contacto</button>
        </Link>
        <Link
          className='row'
          to='/CalculadoraEmisiones'
        >
          <button onClick={() => handler(false)} className='button'>Calculadora de Emisiones</button>
        </Link>
        <div className='redes-sociales-container'>
          <FontAwesomeIcon
            onClick={redirect}
            className='icono'
            icon={faFacebookF}
          />
          <FontAwesomeIcon className='icono' icon={faInstagram} />
          <FontAwesomeIcon className='icono' icon={faYoutube} />
          <FontAwesomeIcon className='icono' icon={faLinkedinIn} />
        </div>
      </div>
    </div>
  );
};

export default ResponsiveMenu;
