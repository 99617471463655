import Index from "../Index/Index";
//import PopUpNotificaciones from "../PopUp/PopUp";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import { Outlet } from "react-router-dom";

const Structure = () => {
    return ( 
        <section className="structure">
            <Header/>
            
            <Outlet/>
            <Footer/>
        </section>
     );
}
 
export default Structure;